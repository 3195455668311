import React, { useEffect } from 'react'
import {
  Token,
  Flex,
  Button,
  VStack,
  InputGroup,
  Box,
  Text,
  Widget,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useFormObserver } from '@src/pages/Forms/GoalForm/Form/Widgets/FormObserverProvider'
import { useGoalFormCache } from '@src/pages/Forms/GoalForm/useGoalFormCache'
import { useGoalFormSubmit } from '@src/pages/Forms/GoalForm/Form/useGoalFormSubmit'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import { ParentGoalField } from '@src/pages/Forms/GoalForm/common/ParentGoalField'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import { ArrowUp } from '@revolut/icons'
import set from 'lodash/set'
import { getGoalPerformanceGraph } from '@src/api/goals'

export const CascadeGoalFormPageBody = ({
  onSubmitted,
  onDirtyChanged,
}: {
  onSubmitted: () => void
  onDirtyChanged: (isDirty: boolean) => void
}) => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, apiErrors, dirty } = goalForm
  const { hasDirtyForms } = useFormObserver()
  const { cacheUpdated } = useGoalFormCache()
  const { submit } = useGoalFormSubmit()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft

  const isDirty = hasDirtyForms() || dirty

  const contentType = values.content_type?.model

  useEffect(() => {
    onDirtyChanged(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (values.update_type?.id !== 'cascaded') {
      values.update_type = { id: 'cascaded', name: 'Cascaded' }
    }
    if (!values.is_submit) {
      set(values, 'is_submit', true)
    }
  }, [])

  // eslint-disable-next-line consistent-return
  const onSubmit = async (updateStatus: boolean) => {
    try {
      await submit(updateStatus)
      onSubmitted()
    } catch (err) {
      // that's ok, validation error
    }
  }

  return (
    <PageBody maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.xl }}>
      {cacheUpdated && (
        <VStack space="s-24">
          <CellWithItem icon="Target" title="General info">
            <InputGroup>
              <LapeRadioSelectInput
                selector={selectorKeys.employee}
                name="owner"
                label="Owner"
                required
              />
              <ParentGoalField
                error={apiErrors.parent || apiErrors.name}
                contentType={contentType}
                required
                value={values.parent}
                onChange={parent => {
                  if (parent) {
                    values.parent = parent
                    values.name = parent.name
                    values.description = parent.description
                  }
                }}
              />
              <LapeNewInput
                name="name"
                label="Goal name"
                disabled
                required
                hasError={!!apiErrors.name}
                errorMessage={apiErrors.name}
              />
              <LapeNewTextArea
                name="description"
                label="Description"
                disabled
                required
                rows={1}
              />
            </InputGroup>
          </CellWithItem>
          <CellWithItem icon="BarChart" title="Preview">
            <Box
              mt="14px"
              mb="s-20"
              p="s-16"
              bg="grey-tone-10"
              color="black-opaque-90"
              radius="widget"
            >
              <Text fontSize="caption">
                Information below is gathered from the chosen higher level Goal.
              </Text>
            </Box>
            {values.parent ? (
              <Widget height={300} pt="s-24" mb="s-8">
                <BaseChartInner
                  fetchData={getGoalPerformanceGraph}
                  id={values.parent.id}
                />
              </Widget>
            ) : (
              <Widget p="s-16">
                <Flex
                  height={224}
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <ArrowUp color="grey-tone-50" />
                  <Text use="p" color="grey-tone-50" pt="s-8">
                    Select Parent Goal to preview its parameters
                  </Text>
                </Flex>
              </Widget>
            )}
          </CellWithItem>
        </VStack>
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDirty && (
            <Button onClick={() => onSubmit(true)} elevated>
              {isDraft ? 'Submit' : 'Save changes'}
            </Button>
          )}
        </Flex>
      </PageActions>
    </PageBody>
  )
}
