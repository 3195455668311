import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import { ScheduleSidebarModeType } from '@src/interfaces/interviewTool'
import {
  CustomDate,
  getTimeZoneId,
  isBeforeCurrent,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { EditDateTime } from '@src/pages/Forms/Candidate/ScheduleSidebar/EditDateTime'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import add from 'date-fns/add'

type Props = {
  mode: ScheduleSidebarModeType
}

const EditManualDate = ({ mode }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const {
    setInterviewer,
    setAdditionalInterviewers,
    setCalendarEvent,
    duration,
    setTimeZone,
  } = useScheduleInterviewContext()
  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const onChangeCustomDate = (
    newCustomDate: CustomDate,
    newTimezoneId: string = timeZoneId,
  ) => {
    values.custom_date = {
      ...newCustomDate,
      timeError: isBeforeCurrent(newCustomDate, newTimezoneId)
        ? 'The time is in the past'
        : '',
    }

    if (newCustomDate.day && newCustomDate.time) {
      const startDate = new Date(newCustomDate.day)

      const time = newCustomDate.time?.split(':')

      if (time?.length === 2) {
        startDate.setHours(+time[0])
        startDate.setMinutes(+time[1])
      }

      setCalendarEvent({
        start: startDate,
        end: add(startDate, {
          minutes: duration,
        }),
      })
    }
  }
  return (
    <InputGroup>
      <LapeRadioSelectInput<EmployeeOptionInterface>
        label="Lead interviewer"
        name="interviewer"
        selector={selectorKeys.employee}
        data-testid="lead-select"
        onAfterChange={value => {
          if (value) {
            setInterviewer(value)
          }
        }}
      />
      <LapeNewMultiSelect<EmployeeOptionInterface>
        name="additional_interviewers"
        placeholder="Additional interviewers"
        selector={selectorKeys.employee}
        variant="grey"
        onAfterChange={value => {
          if (value) {
            setAdditionalInterviewers(value.map(item => item.value))
          }
        }}
      />
      {mode !== 'editing' && (
        <LapeSingleCheckbox name="is_candidate_involved" label="Invite candidate" />
      )}
      <TimezoneSelect
        onChangeTimeZone={newTimezone => {
          if (newTimezone) {
            setTimeZone(newTimezone)
          }
          if (values.custom_date) {
            onChangeCustomDate(values.custom_date, newTimezone?.id)
          }
        }}
      />
      <EditDateTime onChange={onChangeCustomDate} />
    </InputGroup>
  )
}

export default EditManualDate
