import React, { useState, useEffect, useMemo } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { Box, Flex, Tag, chain, MoreBar, Icon } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { useParams, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectPermissions,
  selectFeatureFlags,
  selectUser,
} from '@src/store/auth/selectors'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import Page404 from '@src/pages/Page404/Page404'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes, FeatureFlags, EntityPermissions } from '@src/store/auth/types'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { AnalyticsDashboards } from '@src/pages/Forms/DepartmentForm/AnalyticsDashboards/AnalyticsDashboards'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import Talent from '@src/pages/Forms/DepartmentForm/Talent/Talent'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { useGetOrganisationSettings } from '@src/api/settings'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { TabBarNavigationEditable } from '@src/features/TabBarNavigationEditable/TabBarNavigationEditable'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { Cash } from '@revolut/icons'
import { getStatusColor } from '@components/CommonSC/General'
import upperFirst from 'lodash/upperFirst'
import { Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import {
  useGetDepartment,
  silentDeleteDepartment,
  updateDepartmentStatistics,
  useUpdateDepartment,
  useGetDepartmentApprovals,
} from '@src/api/department'
import { getTalentStats } from '@src/api/talent'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import PageLoading from '@components/PageLoading/PageLoading'
import Leadership from '@src/pages/Forms/DepartmentForm/Leadership/Leadership'
import Kpi from '@src/pages/Forms/DepartmentForm/KPI'
import Roadmap from '@src/pages/Forms/DepartmentForm/Roadmap/Roadmap'
import Teams from '@src/pages/Forms/DepartmentForm/Teams/Teams'
import Risk from '@src/pages/Forms/DepartmentForm/Risk/Risk'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import { Label } from '@components/Label/Label'
import { DetailsSidebar } from '@src/pages/Department/Layout/DetailsSidebar'
import { goBack } from '@src/actions/RouterActions'
import DeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/DeleteOrgUnitButton'
import { UpdateButton } from '@src/pages/Forms/FormButtons'
import { DepartmentPageHeader } from '@src/pages/Department/Layout/common/DepartmentPageHeader'
import { InternalLink } from '@components/InternalLink/InternalLink'
import Budget from '@src/pages/Forms/DepartmentForm/Budget/Budget'
import { Engagement } from '@src/pages/Forms/DepartmentForm/Engagement/Engagement'
import CXCircles from '@components/ColumnInserts/CXCircles/CXCircles'
import CX from '@src/pages/Forms/DepartmentForm/CX'
import { CommunicationActions } from '@src/features/CommunicationGroups/CommunicationActions'
import { Issues } from '@src/pages/Department/Layout/Issues/Issues'
import { IssuesCircles } from '@src/features/IssuesCircles/IssuesCircles'
import { useDepartmentIssuesCounts } from '@src/api/issues'
import { ArchiveButton } from '@src/features/SettingsButtons/ArchiveButton/ArchiveButton'
import useApprovalFlow from '@src/features/ApprovalFlow/useApprovalFlow'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { useCanView } from '@src/pages/Team/helpers'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { RedirectToFirstTab } from '@src/features/TabBarNavigationEditable/RedirectToFirstTab'
import Requisitions from '@src/pages/Forms/DepartmentForm/Requisitions/Requisitions'

const backUrl = ROUTES.ORGANISATION.TEAMS.DEPARTMENTS

export const DepartmentLayout = () => {
  const params = useParams<{ id: string; tab: string }>()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const user = useSelector(selectUser)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { data: settings } = useGetOrganisationSettings()

  const budgetManagementEnabled = !!settings?.enable_budget_management
  const approvalsEnabled = !!settings?.enable_departments_approvals
  const findingsEnabled = !!settings?.findings?.enabled

  const { data, isLoading, isError, error } = useGetDepartment(params.id)

  const entity = useMemo<OrgEntityInterface | undefined>(() => {
    return data
      ? {
          type: EntityTypes.department,
          data,
        }
      : undefined
  }, [data])
  const [stats, setStats] = useState<TalentStatsInterface>()

  const canView = useCanView(data)

  const { approvalButtons, approvalFlow } = useApprovalFlow({
    data: approvalsEnabled ? data : undefined,
    useGetApprovals: useGetDepartmentApprovals,
    statusFieldName: 'approval_status',
    api: useUpdateDepartment,
  })

  const canViewSpentTab = data?.field_options?.permissions?.includes(
    EntityPermissions.ViewSpentTab,
  )
  const canViewCompensationReviews = data?.field_options?.permissions?.includes(
    EntityPermissions.ViewCompensationReviews,
  )

  const { data: issuesCounts } = useDepartmentIssuesCounts(data?.id)

  useEffect(() => {
    if (params.id) {
      getTalentStats('team__department__id', params.id).then(res => {
        if (res.data) {
          setStats(res.data)
        }
      })
    }
  }, [])

  if (isError) {
    return <FormErrorGuard error={error} />
  }

  if (isLoading || !data) {
    return <PageLoading />
  }

  if (!params.id) {
    return <Page404 />
  }

  const isHod = user.id === data.owner?.id
  const canViewMeetingsTab =
    (isHod || permissions.includes(PermissionTypes.ViewMeeting)) &&
    featureFlags.includes(FeatureFlags.OneToOnes) &&
    !featureFlags.includes(FeatureFlags.CommercialProduct)

  const canViewBudget =
    budgetManagementEnabled && (canViewSpentTab || canViewCompensationReviews)

  const externalPages = [
    {
      key: 'budget',
      title: 'Budget',
      path: ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT, params),
      canView: canViewBudget,
      component: Budget,
    },
  ].filter(({ canView: canViewPage }) => canViewPage)

  const tabs = [
    {
      key: 'kpi',
      title: 'KPI',
      icon: <Icon name="Target" size={15} />,
      quickSummary: <ColoredPercent percent={data.kpi_performance_percent * 100} />,
      path: ROUTES.FORMS.DEPARTMENT.KPI,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, params),
      component: Kpi,
      canView: !!data.id && featureFlags.includes(FeatureFlags.CanAddKPIs),
    },
    {
      key: 'goals',
      title: 'Goals',
      icon: <Icon name="Target" size={15} />,
      quickSummary: Number.isFinite(data.goals_progress_percent) && (
        <ColoredPercent percent={data.goals_progress_percent! * 100} />
      ),
      path: ROUTES.FORMS.DEPARTMENT.GOALS.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL, params),
      component: GoalsTab,
      canView: !!data.id && featureFlags.includes(FeatureFlags.CanAddGoals),
    },
    {
      key: 'roadmap',
      title: 'Roadmap',
      icon: <Icon name="Map" size={15} />,
      quickSummary: <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
      path: ROUTES.FORMS.DEPARTMENT.ROADMAP,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, params),
      component: Roadmap,
      canView: canView.canViewRoadmap,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: canView.canViewAnalytics,
      quickSummary: <QuickSummaryCount count={data.dashboard_count} />,
    },
    {
      key: 'leadership',
      title: 'Leadership',
      icon: <Icon name="Gift" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.LEADERSHIP,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.LEADERSHIP, params),
      component: Leadership,
      canView: canView.canViewLeadership,
      quickSummary: chain(
        <QuickSummaryCount count={data.leadership_positions_count} />,
        <QuickSummaryCount
          count={data.unassigned_leadership_positions_count}
          color="red"
        />,
      ),
    },
    {
      key: 'talent',
      title: 'Talent',
      icon: <Icon name="People" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.GENERAL, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: data?.headcount,
        nips: stats?.nips,
      }),
      component: Talent,
      canView: canView.canViewTalent,
    },
    {
      key: 'requisitions',
      title: 'Requisitions',
      icon: <Icon name="AddContact" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.REQUISITIONS, params),
      quickSummary: <QuickSummaryCount count={data.requisition_remaining_headcount} />,
      component: Requisitions,
      canView: canView.canViewRequisitions,
    },
    {
      key: 'teams',
      title: 'Teams',
      icon: <Icon name="Bank" size={15} />,
      quickSummary: <QuickSummaryCount count={data.teams_count} />,
      path: ROUTES.FORMS.DEPARTMENT.STRUCTURE,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.STRUCTURE, params),
      component: Teams,
      canView: !!data.id,
    },
    {
      key: 'issues',
      title: 'Issues',
      icon: <Icon name="Bug" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.ANY,
      to: findingsEnabled
        ? pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.USER, params)
        : pathToUrl(ROUTES.FORMS.DEPARTMENT.ISSUES.TECHNICAL, params),
      component: Issues,
      canView: canView.canViewIssues,
      quickSummary: <IssuesCircles counts={issuesCounts} />,
    },
    {
      key: 'meetings',
      title: 'Meetings',
      icon: <Icon name="Chat" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.MEETINGS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.MEETINGS, params),
      component: MeetingsTracker,
      canView: !!data.id && canViewMeetingsTab,
    },
    {
      key: 'cx',
      title: 'CX',
      icon: <Icon name="Form" size={15} />,
      quickSummary: <CXCircles data={data} />,
      path: ROUTES.FORMS.DEPARTMENT.CX.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.CX.ALL, params),
      component: CX,
      canView: canView.canViewCX,
    },
    {
      key: 'risk',
      title: 'Risk',
      icon: <Icon name="ChangePasscode" size={15} />,
      quickSummary: <RiskCircles data={data} />,
      path: ROUTES.FORMS.DEPARTMENT.RISK.ANY,
      to: pathToUrl(
        canView.canViewRisk
          ? ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY
          : ROUTES.FORMS.DEPARTMENT.RISK.KARMA,
        params,
      ),
      component: Risk,
      canView: canView.canViewRisk || !!settings?.karma?.enabled,
    },
    {
      key: 'engagement',
      title: 'Engagement',
      icon: <Icon name="Heart" size={15} />,
      path: ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.ENGAGEMENT.DRIVERS, params),
      component: Engagement,
      canView: canView.canViewEngagement,
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <Switch>
          {externalPages.map(page => (
            <Route exact key={page.key} path={page.path}>
              {page.title && <DepartmentPageHeader data={data} title={page.title} />}
              <page.component />
            </Route>
          ))}
          <Route>
            <PageHeader
              title={
                <PageHeader.Title
                  title={<>{data.name}</>}
                  useIcon="Bank"
                  labels={
                    <>
                      {data.status === Statuses.archived && (
                        <Tag variant="outlined" color={getStatusColor(data.status)}>
                          {upperFirst(data.status)}
                        </Tag>
                      )}
                      {data.approval_status !== ApprovalStatuses.Approved &&
                      (approvalsEnabled ||
                        data.approval_status !== ApprovalStatuses.Pending) ? (
                        <Tag
                          variant="outlined"
                          color={getStatusColor(data.approval_status)}
                        >
                          {upperFirst(data.approval_status)}
                        </Tag>
                      ) : null}
                      {data.owner?.name ? (
                        <Label
                          name={data.owner.name}
                          icon="Profile"
                          url={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                            id: data.owner.id,
                          })}
                        />
                      ) : null}
                    </>
                  }
                  actions={
                    <>
                      <MoreBar maxCount={approvalButtons !== null ? 4 : 2}>
                        {approvalButtons}
                        <MoreBar.Action
                          useIcon="16/InfoOutline"
                          onClick={() => setSidebarOpen(true)}
                        >
                          View details
                        </MoreBar.Action>
                        <MoreBar.Action
                          useIcon="Pencil"
                          use={InternalLink}
                          to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, {
                            id: data.id,
                          })}
                        >
                          {approvalsEnabled &&
                          data.approval_status === ApprovalStatuses.Rejected
                            ? 'Edit & move to pending'
                            : 'Edit'}
                        </MoreBar.Action>
                        <PermissionTransferButton
                          values={data}
                          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.DEPARTMENT}
                        />
                        {canViewBudget ? (
                          <MoreBar.Action
                            useIcon={Cash}
                            use={InternalLink}
                            to={pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT, {
                              id: data.id,
                            })}
                          >
                            View budget
                          </MoreBar.Action>
                        ) : null}
                        <UpdateButton update={updateDepartmentStatistics} id={data.id} />
                        <CommunicationActions group={data.communication_group} />
                        <ArchiveButton
                          data={data}
                          isVisible={!!data?.id}
                          entityPermissions={EntityPermissions.Change}
                          unArchiveStatus={Statuses.active}
                          api={useUpdateDepartment}
                          showDialog
                        />
                        <DeleteOrgUnitButton
                          onAfterDelete={() => goBack(backUrl)}
                          deleteApi={silentDeleteDepartment}
                          prefix="department"
                          displayName="department"
                          data={data}
                        />
                      </MoreBar>
                    </>
                  }
                />
              }
              backUrl={backUrl}
            >
              <Box my="s-24">
                <TabBarNavigationEditable
                  tabs={filteredTabs}
                  name={TabBarNavigationNames.Department}
                />
              </Box>
            </PageHeader>

            <Flex flex="1 0" flexDirection="column" width="100%">
              <Switch>
                {filteredTabs.map(tab => (
                  <Route exact path={tab.path} key={tab.path}>
                    <tab.component
                      data={data}
                      meetingEntityType={MeetingTrackerEntity.department}
                    />
                  </Route>
                ))}
                <RedirectToFirstTab
                  name={TabBarNavigationNames.Department}
                  tabs={filteredTabs}
                />
              </Switch>
            </Flex>

            <DetailsSidebar
              open={sidebarOpen}
              onClose={() => setSidebarOpen(false)}
              department={data}
              approvalFlow={approvalFlow}
            />
          </Route>
        </Switch>
      </PageWrapper>
    </OrgEntityProvider>
  )
}
