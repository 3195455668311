import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, VStack } from '@revolut/ui-kit'

import { PerformanceSelector, ReviewCategory } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { CheckpointType } from '@src/interfaces/probationReview'
import { useGetPipCheckpoints } from '@src/api/pip'
import SidebarSkeleton from './SidebarSkeleton'
import CheckpointsFilter from './CheckpointsFilter'
import ManagerRecommendations from './ManagerRecommendations'
import ReviewersFilter from './ReviewersFilter'
import Cards from './Cards'
import { useFetchPerformanceSummary } from './hooks'
import { NoReviewsDeliverables } from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsDeliverables'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { SummaryGoals } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryGoals'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { FeedbackSection } from '@src/pages/EmployeeProfile/Layout/Performance/FeedbackSection'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'
import { useHasNewScorecards } from '@src/utils/performance'
import { SummaryReviewTables } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { BarRaiserSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { NoReviewsGoals } from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsGoals'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export interface SummaryWidgetProps {
  cycleId?: string
  cycleName?: string
  category?: ReviewCategory
  predefinedCheckpointFilter?: OptionInterface[]
  predefinedReviewer?: boolean
  employeeName?: string
  employeeSeniority?: string
  employeeSpecialisation?: string
  onClickRequestReview?: () => void
  narrativeCell?: React.ReactNode
  employee: EmployeeInterface
  stats?: EmployeeStats
  selectedPeriod: PerformanceSelector
  periodBeforeOngoing?: PerformanceSelector
  showGoals?: boolean
  setPerformanceLink: Dispatch<SetStateAction<string | undefined>>
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
}

const SummaryWidget = ({
  cycleId,
  category,
  predefinedCheckpointFilter,
  predefinedReviewer = false,
  employee,
  stats,
  selectedPeriod,
  periodBeforeOngoing,
  showGoals = false,
  setPerformanceLink,
  feedback,
  refetchFeedbackList,
}: SummaryWidgetProps) => {
  const [filters, setFilters] = useState<OptionInterface[]>()
  const [checkpointFilters, setCheckpointFilters] = useState<
    OptionInterface[] | undefined
  >(predefinedCheckpointFilter)
  const showNewScorecards = useHasNewScorecards()

  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const employeeId = employee.id
  const employeeSeniorityId = employee.seniority?.id

  const { deleteQueryParam } = useQuery()
  const { data: checkpoints } = useGetProbationCheckpoints(
    category === ReviewCategory.Probation ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const { data: pipCheckpoints } = useGetPipCheckpoints(
    category === ReviewCategory.PIP_V2 ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const {
    data,
    isLoading,
    reviews,
    refresh: refreshSummary,
  } = useFetchPerformanceSummary(cycleId, employeeId, category)

  const { data: previousPeriodData } = useFetchPerformanceSummary(
    periodBeforeOngoing?.id ? String(periodBeforeOngoing.id) : undefined,
    employeeId,
    category,
  )

  const canSeeRecommendations = !!checkpoints?.checkpoints?.filter?.(
    c => c.checkpoint_type === CheckpointType.Recommendation,
  )?.length

  useEffect(() => {
    if (!category || cycleId === undefined || predefinedReviewer) {
      return
    }

    deleteQueryParam(Queries.CheckpointNumber)
    deleteQueryParam(Queries.ReviewerId)
    setFilters(undefined)
    if (predefinedCheckpointFilter) {
      setCheckpointFilters(predefinedCheckpointFilter)
      refreshSummary(undefined, predefinedCheckpointFilter)
    } else {
      setCheckpointFilters(undefined)
      refreshSummary()
    }
  }, [cycleId, employeeId])

  const showPerformanceLink = !!category && !!cycleId
  if (data?.reviews.length && showPerformanceLink) {
    const reviewLink = getReviewsUrl(
      category,
      cycleId,
      employee.id,
      undefined,
      showNewScorecards,
    )
    setPerformanceLink(reviewLink)
  }

  const handleReviewerFilterChange = (options?: OptionInterface[]) => {
    setFilters(options)
    refreshSummary(options, checkpointFilters, false)
  }

  const handleCheckpointsFilterChange = (options?: OptionInterface[]) => {
    setCheckpointFilters(options)
    refreshSummary(filters, options, false)
  }

  return (
    <Box>
      {!!data?.reviews.length && (
        <ReviewersFilter
          data={reviews}
          onFilterChange={handleReviewerFilterChange}
          showFilters={
            category === ReviewCategory.Performance ||
            category === ReviewCategory.Probation ||
            category === ReviewCategory.PIP_V2 ||
            category === ReviewCategory.Upwards
          }
        />
      )}

      {category === ReviewCategory.Probation &&
        !!data?.reviews.length &&
        !predefinedCheckpointFilter && (
          <Box mt="s-8">
            <CheckpointsFilter
              checkpoints={checkpoints?.checkpoints}
              onFilterChange={handleCheckpointsFilterChange}
              showFilters
            />
          </Box>
        )}

      {category === ReviewCategory.PIP_V2 && !predefinedCheckpointFilter && (
        <Box mt="s-8">
          <CheckpointsFilter
            checkpoints={pipCheckpoints?.checkpoints}
            onFilterChange={handleCheckpointsFilterChange}
            showFilters
          />
        </Box>
      )}

      {showGoals && <SummaryGoals data={employee} selectedPeriod={selectedPeriod} />}

      {isLoading ? (
        <SidebarSkeleton />
      ) : (
        <>
          {!data?.reviews.length ? (
            <Box mb="s-16">
              {goalsEnabled ? (
                <>
                  {category === ReviewCategory.Performance && (
                    <NoReviewsGoals
                      summary={data}
                      employee={employee}
                      selectedPeriod={selectedPeriod}
                    />
                  )}
                </>
              ) : (
                <NoReviewsDeliverables
                  data={employee}
                  category={category}
                  stats={stats}
                  selectedPeriod={selectedPeriod}
                />
              )}
              {category === ReviewCategory.Performance &&
                !!previousPeriodData?.reviews.length &&
                previousPeriodData.summary && (
                  <Box mt="s-16">
                    {showNewScorecards ? (
                      <SummaryReviewTables
                        reviewData={previousPeriodData.summary}
                        reviewValues={previousPeriodData}
                        isPreviousValues
                        hideExpectations
                      />
                    ) : (
                      <Cards
                        data={previousPeriodData}
                        cycleId={String(periodBeforeOngoing?.id)}
                        employeeId={employeeId}
                        employeeSeniorityId={employeeSeniorityId}
                        category={ReviewCategory.Performance}
                        showCompetencies={false}
                        wide={false}
                        hideRecommendationBanner
                        cycleName={periodBeforeOngoing?.name}
                        skillsAndValuesOnly
                      />
                    )}
                  </Box>
                )}
            </Box>
          ) : (
            <>
              {data.summary && (
                <Box my="s-24">
                  {showNewScorecards ? (
                    <VStack space="s-24">
                      <SummaryReviewTables
                        reviewData={data.summary}
                        reviewValues={data}
                        hideExpectations
                      />
                      <BarRaiserSummary data={data.summary} hideBanner />
                    </VStack>
                  ) : (
                    <Cards
                      data={data}
                      cycleId={cycleId}
                      employeeId={employeeId}
                      employeeSeniorityId={employeeSeniorityId}
                      category={category}
                      showCompetencies={false}
                      wide={false}
                      hideRecommendationBanner
                    />
                  )}
                </Box>
              )}

              {canSeeRecommendations && checkpoints?.decision_checkpoints && (
                <ManagerRecommendations
                  cycleId={cycleId}
                  employeeId={employeeId}
                  wide={false}
                />
              )}
            </>
          )}
        </>
      )}
      <FeedbackSection
        data={employee}
        feedback={feedback}
        refetchFeedbackList={refetchFeedbackList}
        performanceContent={
          data?.summary?.overall_feedbacks.length ? (
            <SidebarFeedbacks
              feedbacks={data.summary.overall_feedbacks}
              ratings={data.summary.ratings}
              renderTitle={false}
            />
          ) : undefined
        }
      />
    </Box>
  )
}

export default SummaryWidget
