import { DetailsCell, Icon, Token, Text, HStack, TabBar, Box } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { TargetsList } from '../Widgets/Targets/TargetsList'
import { TargetsCharts } from './TargetsCharts'
import { GoalKpiDetails, GoalUpdateType } from '@src/interfaces/goals'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { useGoalTargetsTable } from '@src/features/Goals/common/useGoalTargetsTable'
import { GoalCycleSelectType } from './GoalPreviewPage'
import { GoalTargetSidebarForm } from '@src/features/Goals/GoalTargetSidebarForm'
import { IdAndName } from '@src/interfaces'

export const TargetsPreviewWidget = ({
  cycle,
  onChange,
  updateType,
}: {
  cycle: GoalCycleSelectType
  onChange: (tab: 'chart' | 'table') => void
  updateType?: IdAndName<GoalUpdateType>
}) => {
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const [tab, setTab] = useLocalStorage<'chart' | 'table'>(
    'preferable_goal_target_preview_mode',
    'table',
  )

  const targetsTable = useGoalTargetsTable(tab === 'table' ? undefined : cycle)

  useEffect(() => {
    onChange(tab)
  }, [tab])

  if (!targetsTable.data && !targetsTable.loading) {
    return null
  }

  return (
    <>
      <DetailsCell>
        <DetailsCell.Title>
          <HStack space="s-8">
            <Icon name="TurboTransfer" color={Token.color.greyTone20} />
            <Text variant="h6" color={Token.color.greyTone50}>
              Metrics
            </Text>
          </HStack>
        </DetailsCell.Title>
        <DetailsCell.Content>
          <TabBar
            variant="segmented"
            value={tab}
            onChange={selected => selected && setTab(selected)}
          >
            <TabBar.Item to="chart" aria-label="chart">
              <Text lineHeight="secondary" use="div" mx="s-8">
                <Icon name="BarChart" size={14} />
              </Text>
            </TabBar.Item>
            <TabBar.Item to="table" aria-label="table">
              <Text lineHeight="h2" use="div" mx="s-8">
                <Icon name="16/ListBullet" size={14} />
              </Text>
            </TabBar.Item>
          </TabBar>
        </DetailsCell.Content>
        <DetailsCell.Note>
          {tab === 'table' ? (
            <Box mt="s-16">
              <TargetsList
                onSelected={setSelectedTarget}
                viewMode
                table={targetsTable}
                updateType={updateType}
              />
            </Box>
          ) : (
            <TargetsCharts
              targets={targetsTable.data}
              onEdit={setSelectedTarget}
              updateType={updateType}
            />
          )}
        </DetailsCell.Note>
      </DetailsCell>
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          targetsTable.refresh()
          setSelectedTarget(undefined)
        }}
      />
    </>
  )
}
