import React, { useEffect, useMemo, useState } from 'react'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import {
  TableWidget,
  Highlights,
  TabBar,
  HStack,
  Token,
  Icon,
  Text,
} from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { RoundSelector } from '@src/apps/People/Engagement/Results/components/RoundSelector'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '@src/apps/People/Engagement/Results/hooks'
import Loader from '@src/components/CommonSC/Loader'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ScoreType, ViewType } from '../types'
import { useLocation, useParams } from 'react-router-dom'
import {
  useAdminEngagementResultStats,
  useEngagementSurveyRounds,
} from '@src/api/engagement'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { round, toNumber } from 'lodash'
import { formatPercentage } from '@src/utils/format'
import { TableComponent } from './TableComponent'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { isHubAppPath } from '../helpers/isHubApp'

const TabTooltip = ({ text }: { text: string }) => {
  return (
    <Tooltip placement={'top'} text={text}>
      <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
    </Tooltip>
  )
}

export const SurveyResults = ({ survey }: { survey: EngagementSurveyInterface }) => {
  const { state } = useLocation<{ roundId?: number }>()
  const [selectedRound, setSelectedRound] = useState<IdAndName>()
  const { rounds: options, asyncState } = useSurveyRounds(survey.id)
  const { type: tableType = 'categories' } =
    useParams<{ type: 'categories' | 'questions' | 'comments' }>()
  const [type, setType] = useState<ScoreType>('engagement')
  const [view] = useState<ViewType>('table')
  const {
    data: stats,
    refetch,
    isLoading,
  } = useAdminEngagementResultStats(survey.id, selectedRound?.id)
  const { data: rounds, isLoading: isRoundsLoading } = useEngagementSurveyRounds(
    survey.id,
  )

  // due to peculiar stats calulation on the BE that are difficult to fix for the time being we are first calulating the audience size based on round
  // then fallback to stats
  const audience = useMemo(() => {
    const roundData = rounds?.results.find(result => result.id === selectedRound?.id)
    if (roundData) {
      return {
        audienceSize: roundData.audience_size,
        participationRate: formatPercentage(
          roundData.audience_size
            ? (roundData.response_count || 0) / roundData.audience_size
            : 0,
          2,
        ),
      }
    }
    return {
      audienceSize: stats?.total_headcount,
      participationRate: formatPercentage(stats?.participation_rate || 0, 2),
    }
  }, [rounds, selectedRound])

  useEffect(() => {
    if (asyncState === 'ready') {
      let initRound = options.at(0)
      if (state?.roundId) {
        const preselectedRound = options.find(option => option.id === state.roundId)
        if (preselectedRound) {
          initRound = preselectedRound
        }
      }
      setSelectedRound(initRound)
    }
  }, [asyncState])

  useEffect(() => {
    if (selectedRound) {
      refetch()
    }
  }, [selectedRound])

  if (asyncState === 'pending') {
    return <Loader />
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Highlights>
          <RoundSelector
            showHorizontalNavigation={false}
            value={selectedRound}
            surveyId={survey?.id}
            onChange={value => {
              if (value?.id) {
                setSelectedRound(value)
              }
            }}
          />
          <Stat
            color={overallScoreToColor(stats?.nps_score)}
            val={
              isLoading ? undefined : stats?.nps_score ? round(stats.nps_score, 2) : 'N/A'
            }
            tooltip="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100"
            label="NPS score"
          />
          <Stat
            val={isLoading || isRoundsLoading ? undefined : audience.audienceSize}
            label="Audience size"
            tooltip="This is the number of active employees who were requested to complete the survey"
          />
          <Stat
            tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
            val={isLoading || isRoundsLoading ? undefined : audience.participationRate}
            label="Participation"
            color={participationRateToColor(
              // remove % sign with substring
              Math.round(toNumber(audience.participationRate.slice(0, -1))),
            )}
          />
        </Highlights>
      </TableWidget.Info>
      <TableWidget.Actions>
        <HStack gap="s-16">
          {/* 
          TODO https://revolut.atlassian.net/browse/REVC-6347
          at the moment we just display table version
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={view}
            onChange={value => {
              setView(value || 'table')
            }}
          >
            <TabBar.Item to="chart" useIcon="AvatarGrid" />
            <TabBar.Item to="table" useIcon="Menu" />
          </TabBar> */}
          {tableType !== 'comments' ? (
            <TabBar
              variant="segmented fit"
              mx="auto"
              value={type}
              onChange={value => {
                setType(value || 'engagement')
              }}
            >
              <TabBar.Item to="engagement">
                <HStack space="s-8">
                  <Text>NPS</Text>
                  <TabTooltip text="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100" />
                </HStack>
              </TabBar.Item>
              <TabBar.Item to="average">
                <HStack space="s-8">
                  <Text>Average</Text>
                  <TabTooltip text="Average score is equal to the average of all answers for each particular question or driver, it ranges from 1 to 5" />
                </HStack>
              </TabBar.Item>
            </TabBar>
          ) : null}
        </HStack>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <RadioSelectInput<IdAndName<'categories' | 'questions' | 'comments'>>
          inputProps={{ width: 240 }}
          label="Analyse"
          searchable={false}
          value={{ id: tableType, name: tableType }}
          options={[
            { value: { id: 'categories', name: 'Categories' }, label: 'Categories' },
            { value: { id: 'questions', name: 'Questions' }, label: 'Questions' },
            { value: { id: 'comments', name: 'Comments' }, label: 'Comments' },
          ]}
          onChange={value => {
            if (value) {
              const isHubApp = isHubAppPath()
              navigateReplace(
                pathToUrl(
                  ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DETAILS_RESULTS
                    .ANY,
                  {
                    id: survey.id,
                    type: value.id,
                  },
                ),
              )
            }
          }}
        />
      </TableWidget.Filters>
      <TableWidget.Table>
        <TableComponent
          scoreType={type}
          view={view}
          selectedRound={selectedRound}
          surveyId={survey.id}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
