import React, { useContext, useMemo, useState } from 'react'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  AvailableInterviewerSlot,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { IdAndName } from '@src/interfaces'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'

interface ScheduleInterviewContextInterface {
  interviewer?: EmployeeOptionInterface
  setInterviewer: (interviewer: EmployeeOptionInterface) => void
  additionalInterviewers?: EmployeeOptionInterface[]
  setAdditionalInterviewers: (interviewers: EmployeeOptionInterface[]) => void
  duration?: number
  setDuration: (duration: number) => void
  interviewStage?: InterviewStageWithoutRoundInterface
  setInterviewStage: (stage: InterviewStageWithoutRoundInterface) => void
  durationUnit?: IdAndName<string>
  setDurationUnit: (unit: IdAndName<string>) => void
  calendarEvent?: LargeWeeklyCalendarEventInterface
  setCalendarEvent: (event: LargeWeeklyCalendarEventInterface) => void
  timeZone?: IdAndName<string>
  setTimeZone: (timeZone: IdAndName<string>) => void
  disabledCalendar?: boolean
  setDisabledCalendar: (disabledCalendar: boolean) => void
  selectedSlots: AvailableInterviewerSlot[]
  setSelectedSlots: (selectedSlots: AvailableInterviewerSlot[]) => void
}

const ScheduleInterviewContext = React.createContext<ScheduleInterviewContextInterface>({
  setInterviewer: () => {},
  setAdditionalInterviewers: () => {},
  setDuration: () => {},
  setInterviewStage: () => {},
  setDurationUnit: () => {},
  setCalendarEvent: () => {},
  setTimeZone: () => {},
  setDisabledCalendar: () => {},
  selectedSlots: [],
  setSelectedSlots: () => {},
})

type Props = {
  children: React.ReactNode
}

export const ScheduleInterviewProvider = ({ children }: Props) => {
  const [interviewer, setInterviewer] = useState<EmployeeOptionInterface>()
  const [additionalInterviewers, setAdditionalInterviewers] =
    useState<EmployeeOptionInterface[]>()
  const [duration, setDuration] = useState<number>()
  const [durationUnit, setDurationUnit] = useState<IdAndName<string>>()
  const [interviewStage, setInterviewStage] =
    useState<InterviewStageWithoutRoundInterface>()
  const [calendarEvent, setCalendarEvent] = useState<LargeWeeklyCalendarEventInterface>()
  const [timeZone, setTimeZone] = useState<IdAndName<string>>()
  const [disabledCalendar, setDisabledCalendar] = useState(false)
  const [selectedSlots, setSelectedSlots] = useState<AvailableInterviewerSlot[]>([])

  const value = useMemo(
    () => ({
      interviewer,
      setInterviewer,
      additionalInterviewers,
      setAdditionalInterviewers,
      duration,
      setDuration,
      interviewStage,
      setInterviewStage,
      durationUnit,
      setDurationUnit,
      calendarEvent,
      setCalendarEvent,
      timeZone,
      setTimeZone,
      disabledCalendar,
      setDisabledCalendar,
      selectedSlots,
      setSelectedSlots,
    }),
    [
      interviewer,
      setInterviewer,
      additionalInterviewers,
      setAdditionalInterviewers,
      duration,
      setDuration,
      interviewStage,
      setInterviewStage,
      durationUnit,
      setDurationUnit,
      calendarEvent,
      setCalendarEvent,
      timeZone,
      setTimeZone,
      disabledCalendar,
      setDisabledCalendar,
      selectedSlots,
      setSelectedSlots,
    ],
  )

  return (
    <ScheduleInterviewContext.Provider value={value}>
      {children}
    </ScheduleInterviewContext.Provider>
  )
}

export const useScheduleInterviewContext = () => {
  const context = useContext(ScheduleInterviewContext)
  if (context == null) {
    throw new Error(
      `useScheduleInterviewContext must be used within a ScheduleInterviewContext`,
    )
  }

  return context
}
