import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import {
  Box,
  chain,
  Color,
  Flex,
  StatusWidget,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { LOCAL_STORAGE } from '@src/constants/api'
import {
  EmployeeInterface,
  EmployeeStats,
  InternalOrExternalEmployee,
} from '@src/interfaces/employees'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import {
  useGetOrganisationSettings,
  useGlobalSettings,
  useGetRoadmapSettings,
  useGetTimeOffSettings,
  useGetRequisitionSettings,
  useGetCandidateSettings,
} from '@src/api/settings'
import { getEmployeesStats, getEmployeeWithManagerFilter } from '@src/api/employees'
import { getDynamicGroupsForEmployee } from '@src/api/dynamicGroups'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import Loader from '@components/CommonSC/Loader'
import { PageWrapper } from '@components/Page/Page'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PageHeader } from '@components/Page/Header/PageHeader'
import AuditCircles from '@components/ColumnInserts/AuditCircles/AuditCircles'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import Page404 from '@src/pages/Page404/Page404'
import { FormTitleObsolete } from './Preview/components/FormTitleObsolete'
import { General } from './Forms'
import {
  canViewDocuments,
  canViewOwnership,
  canViewScheduleSettings,
  canViewTimeOff,
  useCanViewMeetingsTab,
} from './Preview/ProfileSummary/common'
import { useEmployeeStatusTransitions } from './Preview/components/Buttons/ChangeStatusDropdown/StatusChange/hooks'
import { useEmployeeInactivityData } from './Preview/components/Buttons/ChangeStatusDropdown/SetInactive/hooks'
import { useEmployeeProfileData } from './Preview/hooks'

// HR Profile tabs
import { ProfileSummary } from './Preview/ProfileSummary'
import { Documents } from './Preview/Documents'
import { Rewards } from './Preview/Rewards'
import { TimeOff } from './Preview/TimeOff'
import { Payroll } from './Preview/Payroll'
import { Schedule } from './Preview/Schedule'
import { Ownership } from './Preview/Ownership'
import { Referrals } from './Preview/Referrals'
import { Labels } from './Preview/Labels'
import { DataAccess } from './Preview/DataAccess'
import { Changelog } from './Preview/Changelog'

// Performance profile tabs
import { PerformanceSummary } from './Preview/PerformanceSummary'
import { Performance } from './Preview/Performance'
import { KPI } from './Preview/KPI'
import { Roadmap } from './Preview/Roadmap'
import { Hiring } from './Preview/ProfileSummary/components/Hiring'
import { Talent } from './Preview/Talent'
import { Engagement } from './Preview/Engagement'
import { Findings } from './Preview/Findings'
import { Risk } from './Preview/Risk'
import { MeetingsTracker } from '@src/pages/Forms/MeetingsTracker/MeetingsTracker'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const ProfileModePicker = styled(Flex)`
  white-space: pre-wrap;
`

export type ProfileMode = 'performance' | 'hr'

const profileModeToDefaultPath: Record<ProfileMode, string> = {
  hr: ROUTES.FORMS.EMPLOYEE.PREVIEW,
  performance: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY,
}

const profileModeToLabel: Record<ProfileMode, string> = {
  hr: 'HR profile',
  performance: 'Performance profile',
}

const hrPaths = {
  profile: ROUTES.FORMS.EMPLOYEE.PREVIEW,
  documents: ROUTES.FORMS.EMPLOYEE.DOCUMENTS,
  rewards: ROUTES.FORMS.EMPLOYEE.REWARDS.ANY,
  payroll: ROUTES.FORMS.EMPLOYEE.PAYROLL,
  ownership: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ANY,
  labels: ROUTES.FORMS.EMPLOYEE.LABELS,
  accessRequests: ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS,
  changelog: ROUTES.FORMS.EMPLOYEE.CHANGELOG,
  timeOff: ROUTES.FORMS.EMPLOYEE.TIME_OFF.ANY,
  schedule: ROUTES.FORMS.EMPLOYEE.SCHEDULE,
  referrals: ROUTES.FORMS.EMPLOYEE.REFERRALS,
  preferences: ROUTES.FORMS.EMPLOYEE.PREFERENCES,
}

const hrRoutes = Object.values(hrPaths)

const performancePaths = {
  summary: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY,
  kpi: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.ANY,
  roadmap: ROUTES.FORMS.EMPLOYEE.ROADMAP,
  performance: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.ANY,
  meetings: ROUTES.FORMS.EMPLOYEE.MEETINGS,
  hiring: ROUTES.FORMS.EMPLOYEE.HIRING,
  talent: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.ANY,
  engagement: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.ANY,
  audit: ROUTES.FORMS.EMPLOYEE.AUDIT,
  risk: ROUTES.FORMS.EMPLOYEE.RISK.ANY,
}

const performanceRoutes = Object.values(performancePaths)
const backUrl = ROUTES.PEOPLE.EMPLOYEES

export const EmployeeForm = () => {
  const user = useSelector(selectUser)
  const params = useParams<{ id?: string }>()
  const featureFlags = useSelector(selectFeatureFlags)

  const isCurrentUser = params?.id && +user.id === +params.id
  const hrRouteMatch = useRouteMatch(hrRoutes)
  const performanceRouteMatch = useRouteMatch(performanceRoutes)

  const { data: organisationSettings } = useGetOrganisationSettings()
  const {
    settings: { attendance_enabled, benefits_enabled, engagement_enabled },
  } = useGlobalSettings()
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const { data: candidatesSettings } = useGetCandidateSettings()

  const timeOffEnabled = !!timeOffSettings?.enabled

  const [data, setData] = useState<EmployeeInterface>()
  const [stats, setStats] = useState<EmployeeStats>()
  const [loading, setLoading] = useState(params.id && !isNaN(parseFloat(params.id)))
  const [dynamicGroups, setDynamicGroups] = useState<DynamicGroupIDs>([])
  const [tabOrder, setTabOrder] = useState<ProfileMode>(() => {
    if (hrRouteMatch) {
      return 'hr'
    }

    if (performanceRouteMatch) {
      return 'performance'
    }

    const storedOrder = workspaceLocalStorage.getItem(
      LOCAL_STORAGE.EMPLOYEE_PROFILE_TAB_ORDER,
    )
    return storedOrder === 'performance' || storedOrder === 'hr'
      ? storedOrder
      : 'performance'
  })
  const isHrView = tabOrder === 'hr'

  const permissions = useSelector(selectPermissions)

  const tabsKey = useMemo(() => JSON.stringify({ ...stats, tabOrder }), [stats, tabOrder])

  const rootMatch = useRouteMatch(ROUTES.FORMS.EMPLOYEE.PROFILE)

  useEffect(() => {
    if (params.id && !isNaN(parseFloat(params.id))) {
      setLoading(true)
      getEmployeeWithManagerFilter(params.id, 'line_manager__id')
        .then(res => {
          setData(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
      refreshStats()
      getDynamicGroups()
    }
  }, [params.id])

  useEffect(() => {
    if (rootMatch) {
      navigateReplace(pathToUrl(profileModeToDefaultPath[tabOrder], params))
    }
  }, [rootMatch, tabOrder])

  useEffect(() => {
    if (hrRouteMatch && tabOrder === 'performance') {
      setTabOrder('hr')
    }
    if (performanceRouteMatch && tabOrder === 'hr') {
      setTabOrder('performance')
    }
  }, [tabOrder, hrRouteMatch, performanceRouteMatch])

  const refreshStats = () => {
    if (params.id) {
      getEmployeesStats(params.id).then(res => {
        setStats(res.data)
      })
    }
  }

  const refreshData = () => {
    if (params.id) {
      getEmployeeWithManagerFilter(params.id, 'line_manager__id').then(res => {
        setData(res.data)
      })
    }
  }

  const getDynamicGroups = () => {
    if (params.id) {
      getDynamicGroupsForEmployee(params.id).then(res => {
        setDynamicGroups(res.data.results)
      })
    }
  }

  const onUpdateEmployeeData = (update: Partial<InternalOrExternalEmployee>) => {
    if (!isEmpty(update)) {
      setData({ ...data, ...update } as EmployeeInterface)
    }
  }

  const statusTransitions = useEmployeeStatusTransitions({
    employee: data,
    updateData: onUpdateEmployeeData,
  })
  const employeeInactivity = useEmployeeInactivityData({ employee: data })

  const isInternal = data?.employee_type === 'internal'
  const profileData = useEmployeeProfileData({
    legacyForm: true,
    employeeId: data?.id,
  })

  const canViewMeetingsTab = useCanViewMeetingsTab(data)

  if (loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }
  if (!data) {
    return <Page404 />
  }

  const hrTabs = [
    {
      title: 'Profile' as const,
      path: hrPaths.profile,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, params),
      component: ProfileSummary,
      canView: true,
    },
    {
      title: 'Documents' as const,
      path: hrPaths.documents,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, params),
      component: Documents,
      canView: canViewDocuments(data),
      quickSummary: stats?.pending_document_count ? (
        <Colored color={Token.color.warning}>{stats.pending_document_count}</Colored>
      ) : null,
    },
    {
      title: 'Rewards' as const,
      path: hrPaths.rewards,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.ANY, params),
      component: Rewards,
      canView:
        benefits_enabled &&
        isInternal &&
        !!data?.field_options?.permissions?.includes(PermissionTypes.ViewRewardsTab),
    },
    {
      title: 'Time Off' as const,
      path: hrPaths.timeOff,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.ANY, params),
      component: TimeOff,
      canView: canViewTimeOff(data, timeOffEnabled),
      quickSummary: stats?.pending_time_off_request_count ? (
        <Colored color={Token.color.warning}>
          {stats.pending_time_off_request_count}
        </Colored>
      ) : null,
    },
    {
      title: 'Payroll' as const,
      path: hrPaths.payroll,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PAYROLL, params),
      component: Payroll,
      canView: featureFlags.includes(FeatureFlags.PayrollEmployee),
    },
    {
      title: 'Schedule' as const,
      path: hrPaths.schedule,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.SCHEDULE, params),
      component: Schedule,
      canView: canViewScheduleSettings(data, attendance_enabled === true),
      quickSummary: null,
    },
    {
      title: 'Ownership' as const,
      path: hrPaths.ownership,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ANY, params),
      component: Ownership,
      quickSummary: stats?.all_ownership_items_count ? (
        <Colored color={Token.color.warning}>{stats.all_ownership_items_count}</Colored>
      ) : null,
      canView: canViewOwnership(data),
    },
    {
      title: 'Referrals' as const,
      path: hrPaths.referrals,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.REFERRALS, params),
      component: Referrals,
      canView:
        candidatesSettings?.enable_referral &&
        (isCurrentUser || permissions.includes(PermissionTypes.ViewApplicationForm)),
    },
    {
      title: 'Labels' as const,
      path: hrPaths.labels,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.LABELS, params),
      component: Labels,
      canView: true,
    },
    {
      title: 'Data Access' as const,
      path: hrPaths.accessRequests,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS, params),
      component: DataAccess,
      canView:
        !!organisationSettings?.data_access_requests?.enabled &&
        !!data?.field_options?.permissions?.includes(PermissionTypes.ViewAccessRequests),
    },
    {
      title: 'Changelog' as const,
      path: hrPaths.changelog,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG, params),
      canView: data.field_options?.permissions?.includes(
        PermissionTypes.ViewEmployeechangelogitem,
      ),
      quickSummary: stats?.upcoming_changes_count ? (
        <Colored color={Token.color.warning}>{stats.upcoming_changes_count}</Colored>
      ) : null,
      component: Changelog,
    },
  ]

  const performanceTabs = [
    {
      title: 'Summary' as const,
      path: performancePaths.summary,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, params),
      canView: true,
      component: PerformanceSummary,
    },
    {
      title: 'Performance' as const,
      path: performancePaths.performance,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, params),
      component: Performance,
      canView: true,
    },
    {
      title: 'KPIs' as const,
      quickSummary: stats && (
        <ColoredPercent percent={stats.kpi_performance_percent * 100} />
      ),
      path: performancePaths.kpi,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, params),
      canView: true,
      component: KPI,
    },
    {
      title: 'Roadmap' as const,
      path: performancePaths.roadmap,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, params),
      component: Roadmap,
      quickSummary: stats && (
        <ColoredPercent percent={stats.roadmap_progress_percent * 100} />
      ),
      canView: !!roadmapSettings?.enabled,
    },
    {
      title: 'Meetings' as const,
      path: performancePaths.meetings,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.MEETINGS, params),
      component: MeetingsTracker,
      canView: canViewMeetingsTab,
    },
    {
      title: 'Hiring' as const,
      path: performancePaths.hiring,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.HIRING, params),
      component: Hiring,
      quickSummary: stats && (
        <QuickSummaryCount count={stats.line_manager_requisition_headcount} />
      ),
      canView: requisitionSettings?.enabled,
    },
    {
      title: 'Talent' as const,
      path: performancePaths.talent,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
        params,
      ),
      component: Talent,
      quickSummary: getTalentStatsQuickSummary({
        headcount: stats?.direct_reports_count,
        nips: stats?.direct_reports_nips,
      }),
      canView: true,
    },
    {
      title: 'Engagement',
      path: performancePaths.engagement,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.DRIVERS,
        params,
      ),
      component: Engagement,
      canView:
        engagement_enabled &&
        isInternal &&
        permissions.includes(PermissionTypes.ViewEngagementTabs) &&
        data.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs),
    },
    {
      title: 'Findings' as const,
      path: performancePaths.audit,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.AUDIT, params),
      quickSummary: stats && <AuditCircles data={stats} />,
      component: Findings,
      canView:
        !!organisationSettings?.findings?.enabled &&
        !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeeAudits),
    },
    {
      title: 'Risk' as const,
      path: performancePaths.risk,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.RISK.SUMMARY, params),
      component: Risk,
      quickSummary: stats && <RiskCircles data={stats} />,
      canView:
        !!organisationSettings?.risk?.enabled &&
        data.field_options.permissions?.includes(EntityPermissions.ViewKri),
    },
  ]

  const onChangeTabOrder = (newOrder: ProfileMode) => {
    workspaceLocalStorage.setItem(LOCAL_STORAGE.EMPLOYEE_PROFILE_TAB_ORDER, newOrder)

    if (newOrder !== tabOrder) {
      setTabOrder(newOrder)
      navigateReplace(pathToUrl(profileModeToDefaultPath[newOrder], params))
    }
  }

  const filteredTabs = [...(isHrView ? hrTabs : performanceTabs)].filter(
    tab => tab.canView,
  )

  const isShadowUser = data.anonymized?.is_shadow

  if (isShadowUser) {
    return (
      <PageWrapper>
        <Box maxWidth={Token.breakpoint.md} mt="s-64" mx="auto">
          <StatusWidget>
            <StatusWidget.Image
              src="https://assets.revolut.com/assets/3d-images/3D020.png"
              srcSet="https://assets.revolut.com/assets/3d-images/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D020@3x.png 3x"
            />
            <StatusWidget.Title>Anonymous Profile</StatusWidget.Title>
            <StatusWidget.Description>
              This profile is hidden because it is used to store employee data that has
              been anonymised.
            </StatusWidget.Description>
            <StatusWidget.Action onClick={() => goBack(ROUTES.PEOPLE.EMPLOYEES)}>
              Go Back
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <Switch>
        <Route path={ROUTES.FORMS.EMPLOYEE.GENERAL.ANY} exact>
          <General data={data} dynamicGroups={dynamicGroups} refreshData={refreshData} />
        </Route>
        <Route>
          <PageHeader title={<FormTitleObsolete data={data} />} backUrl={backUrl}>
            <Box mt="-s-16" mb="s-8">
              <ProfileModePicker alignItems="center" mb="s-4" color={Color.GREY_TONE_50}>
                {chain(
                  <Text
                    fontWeight="500"
                    data-testid="selected-profile"
                    fontSize="caption"
                  >
                    {profileModeToLabel[tabOrder]}
                  </Text>,
                  <TextButton
                    onClick={() => onChangeTabOrder(isHrView ? 'performance' : 'hr')}
                    p={0}
                  >
                    {isHrView ? 'Switch to performance profile' : 'Switch to HR profile'}
                  </TextButton>,
                )}
              </ProfileModePicker>
              <TabBarNavigation tabs={filteredTabs} key={tabsKey} />
            </Box>
          </PageHeader>

          <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
            <Switch>
              {filteredTabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <tab.component
                    data={data}
                    dynamicGroups={dynamicGroups}
                    stats={stats}
                    refreshStats={refreshStats}
                    updateData={onUpdateEmployeeData}
                    refreshData={refreshData}
                    statusTransitions={statusTransitions}
                    employeeInactivity={employeeInactivity}
                    sectionsData={profileData.sections}
                    meetingEntityType={MeetingTrackerEntity.employee}
                  />
                </Route>
              ))}
              {(tabOrder === 'performance' && !hrRouteMatch) ||
              (isHrView && !performanceRouteMatch) ? (
                <InternalRedirect to={filteredTabs[0].to} />
              ) : null}
            </Switch>
          </Flex>
        </Route>
      </Switch>
    </PageWrapper>
  )
}
