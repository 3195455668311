import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { TeamGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsCardTable'
import { EmployeeGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { TableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'
import { ContributorType } from '@src/interfaces/talent/performance'

export const NoReviewsGoals = ({
  summary,
  employee,
  selectedPeriod,
}: {
  summary?: ReviewSummaryInterface
  employee: EmployeeInterface
  selectedPeriod: PerformanceSelector
}) => {
  const [goalsStat, setGoalsStat] = useState<number>()
  const team = summary?.team
  const gradesMap = summary?.grade_label_mapping

  if (!summary || !gradesMap) {
    return null
  }

  const hasIndividualKpi = summary.reviewed_employee_type !== ContributorType.IC

  return (
    <TableWrapper
      headerTitle={hasIndividualKpi ? 'Goals' : 'Team Goals'}
      headerStat={goalsStat}
    >
      {hasIndividualKpi ? (
        <EmployeeGoalsCardTable
          gradesMap={gradesMap}
          setGoalsStat={setGoalsStat}
          noWidget
          employeeId={employee.id}
          cycle={selectedPeriod}
        />
      ) : (
        <TeamGoalsCardTable
          gradesMap={gradesMap}
          cycle={selectedPeriod}
          team={team}
          noWidget
        />
      )}
    </TableWrapper>
  )
}
