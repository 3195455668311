import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useParams } from 'react-router-dom'
import { ManualScheduleInterview } from '@src/pages/Forms/Candidate/ScheduleInterview/ManualScheduleInterview'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import { ScheduleInterviewProvider } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { CandidateProfileContextProvider } from '@src/pages/Forms/Candidate/CandidateProfileContext'

export const NewInterviewScheduling = () => {
  const { candidateId } = useParams<{ candidateId: string }>()
  const params = useParams()

  // TODO: not being used currently https://revolut.atlassian.net/browse/PERF-5670
  const tabs = [
    // {
    //   title: 'Suggest interview slots',
    //   path: ROUTES.FORMS.SCHEDULE_INTERVIEW.SLOTS,
    //   to: pathToUrl(ROUTES.FORMS.SCHEDULE_INTERVIEW.SLOTS, params),
    //   component: SlotsScheduleInterview,
    // },
    {
      title: 'Manual scheduling',
      path: ROUTES.FORMS.SCHEDULE_INTERVIEW.MANUAL,
      to: pathToUrl(ROUTES.FORMS.SCHEDULE_INTERVIEW.MANUAL, params),
      component: ManualScheduleInterview,
    },
  ]
  return (
    <ScheduleInterviewProvider>
      <MainLayout>
        <CandidateProfileContextProvider>
          <PageWrapper>
            <PageHeader
              title="Schedule interview"
              backUrl={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId })}
            />
            {/* <Flex alignItems="flex-start" mb="s-16"> */}
            {/*  <TabBarNavigation tabs={tabs} /> */}
            {/* </Flex> */}
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <tab.component />
                </Route>
              ))}
            </Switch>
          </PageWrapper>
        </CandidateProfileContextProvider>
      </MainLayout>
    </ScheduleInterviewProvider>
  )
}
