import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalKpiDetails } from '@src/interfaces/goals'
import { CellWithItem } from '../../../common/CellWithItem'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ActionButton } from '@revolut/ui-kit'

import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { GoalMetricsItems } from './GoalMetricItem'
import { DeepPartial } from 'redux'
import { useAddMetric } from './useAddMetrics'

interface TargetsWidgetProps {
  reviewCycle?: ReviewCyclesInterface
}

export const TargetsWidget = ({ reviewCycle }: TargetsWidgetProps) => {
  const { values } = useLapeContext<
    GoalsInterface & { kpis: DeepPartial<GoalsInterface['kpis']> }
  >()

  const hasDeletePermission = !!values.field_options?.permissions?.includes('delete_goal')

  const { data: reviewCycles, isFetching } = useGetSelectors<ReviewCyclesInterface>(
    selectorKeys.review_cycles,
  )
  const { getNextMetric, getEmptyMetric } = useAddMetric({ values, reviewCycles })

  const onAddNewMetric = (copyFromId?: number) => {
    const nextMetric = getNextMetric({ copyFromId })
    values.kpis.push(nextMetric)
  }

  const addEmptyMetric = () => {
    const initReviewCycle =
      reviewCycle || reviewCycles?.find(cycle => Number(cycle.offset) === 0)

    const emptyMetric: DeepPartial<GoalKpiDetails> = getEmptyMetric(initReviewCycle)
    values.kpis.push(emptyMetric)
  }

  useEffect(() => {
    if (!values.kpis.length && !isFetching) {
      addEmptyMetric()
    }
  }, [isFetching])

  return (
    <CellWithItem
      icon="TurboTransfer"
      title="Metric"
      description="What are the metrics and success criteria?"
    >
      <GoalMetricsItems
        metrics={values.kpis}
        onCopy={onAddNewMetric}
        hasDeletePermission={hasDeletePermission}
      />
      {values.update_type?.id === 'target_based' ? (
        <ActionButton useIcon="Plus" onClick={() => onAddNewMetric()}>
          Add metric
        </ActionButton>
      ) : null}
    </CellWithItem>
  )
}
