import { FieldOptions } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export enum AnytimeFeedbackVisibility {
  ManagersOnly = 'managers_only',
  EmployeeAndManagers = 'employee_and_managers',
  EmployeeOnly = 'employee_only',
  Private = 'private',
}

export const AnytimeFeedbackVisibilityTitles = {
  [AnytimeFeedbackVisibility.ManagersOnly]: 'Managers only',
  [AnytimeFeedbackVisibility.EmployeeAndManagers]: 'Employee and managers',
}

export enum FeedbackCategory {
  Anytime = 'anytime',
  OneToOne = 'one_to_one',
  Recognition = 'recognition',
  Performance = 'performance',
}

export const FeedbackCategoryTitles: { [key: string]: string } = {
  [FeedbackCategory.Anytime]: 'Anytime',
  [FeedbackCategory.OneToOne]: '1:1',
  [FeedbackCategory.Recognition]: 'Recognition',
}

export enum AnytimeFeedbackRating {
  Superpower = 'superpower',
  Performing = 'performing',
  ImprovementArea = 'improvement_area',
}

export const AnytimeFeedbackRatingTitles = {
  [AnytimeFeedbackRating.Superpower]: 'Superpower',
  [AnytimeFeedbackRating.Performing]: 'Performing',
  [AnytimeFeedbackRating.ImprovementArea]: 'Improvement Area',
}

export interface AnytimeFeedbackInterface {
  id: number
  reporter: EmployeeOptionInterface
  employee: EmployeeOptionInterface
  updated_by: EmployeeOptionInterface | null
  creation_date_time: string
  update_date_time: string
  body: string
  labels?: string[]
  visibility: AnytimeFeedbackVisibility | null
  category: FeedbackCategory
  rating: AnytimeFeedbackRating
  field_options: FieldOptions
  content_object_title?: string
  content_object_link?: string
  content_object_event_link?: string
  content_object_description?: string
  object_id?: number
  // TODO: https://revolut.atlassian.net/browse/REVC-6274 - release once BE ready
  // can_edit?: boolean
}

export interface AnytimeFeedbackListInterface {
  count: number
  results: AnytimeFeedbackInterface[]
}

export interface AnytimeFeedbackLabel {
  id: string
  name: string
}
