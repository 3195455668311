import React from 'react'
import { useParams } from 'react-router-dom'
import { StatusWidget } from '@revolut/ui-kit'

import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import { useGetImportSessionData } from '@src/api/bulkDataImport'
import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { useDynamicTemplateQueryParams } from './helpers'
import { BulkDataImportSessionProps } from './types'
import { PageBody } from '@src/components/Page/PageBody'

export const BulkDataImportSessionV2 = <T,>(props: BulkDataImportSessionProps<T>) => {
  const params = useParams<{ id: string; type?: string }>()

  const { data, refetch } = useGetImportSessionData(props.apiEndpoint, params.id)

  useDynamicTemplateQueryParams({
    apiEndpoint: props.apiEndpoint,
    apiVersion: props.apiVersion,
    sessionData: data,
  })

  if (!data) {
    return <PageLoading />
  }

  if (data.state.id === 'failure' && data.source === 'google') {
    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
            }}
          />
          <StatusWidget.Title>Import failed</StatusWidget.Title>
          <StatusWidget.Description>
            Please ensure you have admin permissions and try again
          </StatusWidget.Description>
        </StatusWidget>
      </PageBody>
    )
  }

  if (data.state.id === 'pending' || data.state.id === 'processing_file') {
    return <ProcessingFileState />
  }

  const disabled =
    data.state.id === 'success' ||
    data.state.id === 'failure' ||
    data.state.id === 'applying'

  return (
    <GenericEditableTable
      apiEndpoint={props.apiEndpoint}
      tableName={props.tableName}
      table={props.table}
      refetchSessionData={refetch}
      row={props.row}
      sessionData={data}
      actions={props.actions}
      disabled={disabled}
      entity={props.entity}
      variant="temporaryEntities"
      tableActions={props.tableActions}
      hiddenColumns={props.hiddenColumns}
      ignoreQueryParams={props.ignoreQueryParams}
      getCustomEditCellAction={props.getCustomEditCellAction}
      onActionColumnPreview={props.onActionColumnPreview}
      refreshTableHandler={props.refreshTableHandler}
    />
  )
}
