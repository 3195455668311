import { format, isBefore, parse } from 'date-fns'
import pluralize from 'pluralize'
import {
  InterviewPendingSchedulingStatuses,
  InterviewPendingSchedulingTypes,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { getCurrentTimezone, getDate, getTime } from '@src/utils/timezones'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import { IdAndName } from '@src/interfaces'

export type CustomDate = {
  day?: string
  time?: string
  timeError?: string
}

export type Timezone = {
  id: string
  name: string
  offset?: string
}

export function parseDay(day: string) {
  return parse(day, 'yyyy-MM-dd', new Date())
}

export const parseCustomDate = ({ time, day }: CustomDate) => {
  return parse(`${day} ${time}`, 'yyyy-MM-dd HH:mm', new Date())
}

export const getFullTime = ({ time, day }: CustomDate) => {
  if (!time || !day) {
    return undefined
  }
  return format(parseCustomDate({ time, day }), 'd MMM yyyy hh:mm a')
}

export const getDuration = (duration: number | null, durationUnit: string) =>
  `${duration} ${pluralize(durationUnit, duration || undefined)}`

export const getPendingSchedulingStages = (
  stages: InterviewStageWithoutRoundInterface[],
) =>
  stages.filter(
    item =>
      InterviewPendingSchedulingStatuses.includes(item.scheduling_status) &&
      InterviewPendingSchedulingTypes.includes(item.interview_type),
  )

export const dateToCustomDate = (date: string | Date, timezoneId: string): CustomDate => {
  const zoned = utcToZonedTime(date, timezoneId)
  return {
    day: getDate(zoned),
    time: getTime(zoned),
  }
}

export const customDateToDate = (customDate: CustomDate, timezoneId: string) => {
  return zonedTimeToUtc(parseCustomDate(customDate), timezoneId)
}

export function isBeforeCurrent(customDate: CustomDate, timezone: string) {
  const current = new Date()
  const custom = customDateToDate(customDate, timezone)
  return isBefore(custom, current)
}

export const getTimeZoneId = (timezone?: IdAndName<string>) => {
  const currentTimezoneId = getCurrentTimezone()
  return timezone ? String(timezone.id) : currentTimezoneId
}

export const getUtcCustomDate = (timeZoneId: string, customDate?: CustomDate) =>
  customDate?.day &&
  customDate?.time &&
  customDateToDate(customDate, timeZoneId).toISOString()
