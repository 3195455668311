import { AxiosPromise, AxiosResponse, AxiosError } from 'axios'
import { api, apiWithoutHandling } from '@src/api'
import { EntityTypes, API } from '@src/constants/api'
import { GetRequestInterface, Id, RequestInterfaceNew } from '@src/interfaces'
import {
  AccessDashboardRequestInterface,
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardRatingInterface,
  GenericAnalyticsDashboardInterface,
  InternalDashboardInterface,
  TenantDashboardDeploymentInterface,
} from '@src/interfaces/analyticsDashboards'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { UseQueryOptions, useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { useQueryClient, useMutation } from 'react-query'

export const getAnalyticsDashboards =
  (entityType: EntityTypes | 'goal', id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<AnalyticsDashboardInterface>
  > =>
    api.get(`/data/dashboards`, {
      params: {
        ...(id && { [`related_${entityType}s`]: id }),
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getAnalyticsDashboardsOrdered =
  (entityType: EntityTypes, id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<GenericAnalyticsDashboardInterface>
  > =>
    api.get(`/data/dashboards/ordering`, {
      params: {
        ...(id && { [`related_${entityType}s`]: id }),
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getAllLookerDashboards = (): AxiosPromise<
  GetRequestInterface<AnalyticsDashboardInterface>
> => api.get(`/data/dashboards/looker`)

export const useLookerDashboard = (
  id: number | undefined,
  queryOptions?: UseQueryOptions<AnalyticsDashboardInterface>,
) => {
  return useFetch<AnalyticsDashboardInterface>({
    url: id ? `/data/dashboards/looker/${id}` : null,
    queryOptions,
  })
}

export const dashboardRequestsNew: RequestInterfaceNew<AnalyticsDashboardInterface> = {
  get: async ({ id }) => api.get(`/data/dashboards/looker/${id}`),
  update: async (_, { id }, data) => api.put(`/data/dashboards/looker/${id}`, data),
  submit: async data => api.post(`/data/dashboards/looker`, data),
  delete: async ({ id }) => api.delete(`/data/dashboards/looker/${id}`),
}

export const internalDashboardRequests: RequestInterfaceNew<InternalDashboardInterface> =
  {
    get: async ({ id }) => api.get(`/data/dashboards/internal/${id}`),
    update: async (_, { id }, data) => api.put(`/data/dashboards/internal/${id}`, data),
    submit: async data => api.post(`/data/dashboards/internal`, data),
    delete: async ({ id }) => api.delete(`/data/dashboards/internal/${id}`),
  }

export const useGetInternalDashboard = (id?: string) => {
  return useFetch<InternalDashboardInterface>({
    url: id ? `/data/dashboards/internal/${id}` : null,
  })
}

export const createRelatedCompany = (dashboardId: number | string) =>
  api.post(`/data/dashboards/${dashboardId}/companyRelated`)

export const createRelatedDepartments = (
  dashboardId: number | string,
  departmentId: number,
) => api.post(`/data/dashboards/${dashboardId}/relatedDepartments/${departmentId}`)

export const createRelatedTeams = (dashboardId: number | string, teamId: number) =>
  api.post(`/data/dashboards/${dashboardId}/relatedTeams/${teamId}`)

export const createRelatedEmployees = (
  dashboardId: number | string,
  employeeId: number,
) => api.post(`/data/dashboards/${dashboardId}/relatedEmployees/${employeeId}`)

export const createRelatedGoals = (dashboardId: number | string, goalId: number) =>
  apiWithoutHandling.post(`/data/dashboards/${dashboardId}/relatedGoals/${goalId}`)

export const deleteRelatedGoals = (dashboardId: number | string, goalId: number) =>
  apiWithoutHandling.delete(`/data/dashboards/${dashboardId}/relatedGoals/${goalId}`)

export const deleteRelatedCompany = (dashboardId: number | string) =>
  api.delete(`/data/dashboards/${dashboardId}/companyRelated`)

export const deleteRelatedDepartments = (
  dashboardId: number | string,
  departmentId: number,
) => api.delete(`/data/dashboards/${dashboardId}/relatedDepartments/${departmentId}`)

export const deleteRelatedTeams = (dashboardId: number | string, teamId: number) =>
  api.delete(`/data/dashboards/${dashboardId}/relatedTeams/${teamId}`)

export const deleteRelatedEmployees = (
  dashboardId: number | string,
  employeeId: number,
) => api.delete(`/data/dashboards/${dashboardId}/relatedEmployees/${employeeId}`)

export const activateDashboard = (id: string | number) =>
  api.put<AnalyticsDashboardInterface>(`/data/dashboards/${id}/activate`)

export const archiveDashboard = (id: string | number) =>
  api.put<AnalyticsDashboardInterface>(`/data/dashboards/${id}/archive`)

export const createDashboardRating = (
  dashboardId: number,
  data: Pick<GenericAnalyticsDashboardRatingInterface, 'score' | 'comment' | 'labels'>,
) => api.post(`/data/dashboards/${dashboardId}/ratings`, data)

export const accessDashboardRequest = (
  dashboardId: number,
  data: AccessDashboardRequestInterface,
) =>
  apiWithoutHandling.post(`/data/dashboards/looker/${dashboardId}/accessRequests`, data)

export const updateDashboardsOrder = (
  data: UpdateOrderingInterface,
  filters: FilterByInterface[],
) =>
  api.post(`/data/dashboards/ordering/itemPositionUpdate`, data, {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const updateDashboard = (diff: Id & Partial<GenericAnalyticsDashboardInterface>) =>
  apiWithoutHandling.patch(`/data/dashboards/looker/${diff.id}`, diff)

export const useSaveDashboardDeployments = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<TenantDashboardDeploymentInterface>,
    AxiosError,
    Omit<TenantDashboardDeploymentInterface, 'id'>
  >(data => apiWithoutHandling.post(`${API.TENANTS}/dashboardDeployments`, data), {
    onSuccess: response => {
      if (response.data) {
        queryClient.setQueryData<InternalDashboardInterface>(
          [`/data/dashboards/internal/${response.data.dashboard_id}`],
          oldData => ({ ...oldData!, tenant_deployment: response.data }),
        )
      }
    },
  })
}

export const useUpdateDashboardDeployments = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<TenantDashboardDeploymentInterface>,
    AxiosError,
    [string | number, Omit<TenantDashboardDeploymentInterface, 'id'>]
  >(
    ([id, data]) =>
      apiWithoutHandling.put(`${API.TENANTS}/dashboardDeployments/${id}`, data),
    {
      onSuccess: response => {
        if (response.data) {
          queryClient.setQueryData<InternalDashboardInterface>(
            [`/data/dashboards/internal/${response.data.dashboard_id}`],
            oldData => ({ ...oldData!, tenant_deployment: response.data }),
          )
        }
      },
    },
  )
}
